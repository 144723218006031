import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const DomesticViolence = () => (
  <Layout>
    <PageTitle title="Domestic Violence Attorney" page="PageTitleDomestic" />
    <Seo title="Domestic Violence Lawyer in Annapolis MD" description="If you're seeking an order of protection or defending against an order, Sean has successfully worked with clients across Maryland on these issues. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            Crimes of domestic violence are abusive acts committed between members of the same family or household. Depending on the circumstances involved, courts may issue a protective order or a peace order in domestic violence situations. It is important to understand the terms of these orders because violating the terms constitutes a separate criminal offense. Those in a domestic relationship may seek a protective order from the court to prohibit another person in the relationship from making contact or taking certain actions. 
            </p>
            <p>Maryland law allows someone to seek an order against a current or former spouse, a person related by blood, marriage or adoption, a person with whom they have parented a child, a person with whom they have had an intimate relationship for at least three months during the past year, a step-parent or step-child with whom they have resided for at least three months of the past year, a person they have had sex with during the past year, or another person sharing a caretaker/vulnerable adult relationship. Terms of the protective order will vary according to the petition and circumstances. An order may require the other party to stay out of a home, refrain from all contact, and temporarily lose custody of shared children and pets. </p>
                
            <p>
            Those who are seeking protection from unwanted contact but who lack the degree of relationship required for a protective order may seek a peace order. Whether seeking an order of protection or defending against an order, Sean has successfully worked with clients across Maryland on these issues.
            </p>
        </div> 
    </div>        
  </Layout>
)

export default DomesticViolence
